var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('video',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.playVideo,
    intersection: {
      rootMargin: '20px',
      threshold: 0,
    },
  }),expression:"{\n    callback: playVideo,\n    intersection: {\n      rootMargin: '20px',\n      threshold: 0,\n    },\n  }"}],staticClass:"ct-video-media",style:({
    height: _vm.data.height ? ((_vm.data.height) + "vh") : '100%',
    objectFit: 'cover'
  }),attrs:{"muted":"","loop":"","playsinline":"","preload":"none","poster":_vm.poster},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.video.filename,"type":"video/mp4"}}),_vm._v("\n  Your browser does not support the video tag.\n")])}
var staticRenderFns = []

export { render, staticRenderFns }