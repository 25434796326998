

























import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { StoryblokAsset } from '../../../types';

@Component
export default class VideoMedia extends Vue {
  @Prop() video!: StoryblokAsset;
  @Prop() data!: { [key: string]: any };
  get poster (): string|false {
    if (!this.data?.poster) { return false; }
    const poster = this.data.poster as StoryblokAsset;
    if (!poster.filename) { return false; }
    return `${poster.filename}/m/100x100/filters:quality(100):blur(30)`;
  }
  playVideo (isVisible: boolean, entry: IntersectionObserverEntry): void {
    const video = entry.target as HTMLVideoElement;
    if (isVisible) {
      video.play();
    } else {
      const isVideoPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2) as boolean;
      if (isVideoPlaying) {
        video.pause();
      }
    }
  }
}
